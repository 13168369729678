@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  margin: 0 auto;
  width: 50%;
}

h1 {
  border-bottom: 1px solid #ddd;
}

form {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search {
  display: flex;
  justify-content: center;
}

input[type="text"]{
  background-color: #f4f4f4;
  margin: 0;
  width: 50%;
  padding: 14px 16px;
  font-family: 'Lato', sans-serif;
  color: #008CBA;
  outline: none;
  font-size: 1.2rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: none;
}

.input-button {
  margin: 0;
  background-color: #f4f4f4;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border: none;
  font-size: 1.2rem;
  padding: 14px 16px;
}

.input-button:hover, .input-button:active, .input-button:focus {
  filter: brightness(100%);
}

input[type="radio"]{
  margin-top: 15px;
}

button {
  cursor: pointer;
  background-color: #dddddd;
  padding: 10px 12px;
  margin-bottom: 10px;
  margin-left: 8px;
  font-family: 'Lato', sans-serif;
  font-size: .8rem;
  outline: none;
  border-radius: 20px;
  border: none;
  transition: .3s;
}

button:hover, button:active, button:focus {
  filter: brightness(90%);
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 10px 0 10px 0;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tbody:nth-child(even) {
  background-color: #f6f6f6;
}

.wiki {
  text-align: left;
}

footer {
  font-size: .8rem;
  color: #b9b9b9;
  text-align: center;
  margin: 20px;
}

a {
  color: #008CBA;
}

a:hover {
  text-decoration: none;
}

.message {
  text-align: center;
  color: #008CBA;
}

@media only screen and (max-width: 800px) {
  body {
    width: 90%;
  }
}